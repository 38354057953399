<template>
  <div>
    <h1>Reset your RateRabbit password</h1>

    <div v-if="passwordResetSuccess" class="alert alert-success alert-dismissible fade show" role="alert">
      <strong>Password changed successfully! </strong>
      <button type="button" class="close" aria-label="Close" @click="closePasswordResetSuccessAlert">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div v-else-if="passwordResetFail" class="alert alert-danger alert-dismissible fade show" role="alert">
      <strong>Failed to reset password. Please try to reset your password again. </strong>
      <button type="button" class="close" aria-label="Close" @click="closePasswordResetFailAlert">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <form v-else class="custom-login-form" v-on:submit.prevent="onSubmit">
      <p v-if="passwordEmpty" class="error">
        Password cannot be empty!
      </p>
      <p v-if="passwordMismatch" class="error">
        Password mismatch!
      </p>
      <div class="form-group">
        <label for="newPassword">New password</label>
        <input
          v-model="newPassword"
          type="password"
          class="form-control"
          id="newPassword"
          placeholder="Password"
        />
      </div>
      <div class="form-group">
        <label for="confirmPassword">Confirm password</label>
        <input
            v-model="confirmPassword"
            type="password"
            class="form-control"
            id="confirmPassword"
            placeholder="Password"
        />
      </div>
      <b-row class="text-center m-2" align-v="center">
        <b-col class="pb-1 text-right">
          <button type="submit" class="btn btn-secondary">Reset</button>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>

import ForgotPassword from "@/services/ForgotPasswordService";

export default {
  name: 'login',
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  },
  data: function () {
    return {
      token: '',
      newPassword: '',
      confirmPassword: '',

      passwordMismatch: false,
      passwordEmpty: false,
      passwordResetSuccess: false,
      passwordResetFail: false
    };
  },
  created: async function () {
    this.token = this.$route.params.token;
  },
  methods: {
    closePasswordResetSuccessAlert: function () {
      this.$router.push({
        name: 'Login'
      });
    },
    closePasswordResetFailAlert: function () {
      this.$router.push({
        name: "Login"
      })
    },
    onSubmit: async function () {
      this.passwordEmpty = !this.newPassword;

      this.passwordMismatch = this.confirmPassword !== this.newPassword;

      if (!this.passwordEmpty && !this.passwordMismatch) {
        let resetPasswordInput = {
          token: this.token,
          password: this.newPassword
        }

        let result = await ForgotPassword.resetPassword(resetPasswordInput);

        if (result) {
          this.passwordResetSuccess = true;
        } else {
          this.passwordResetFail = true;
        }
      }
    },
  }
}
</script>
